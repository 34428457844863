<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div :class="node.id === firstNodeId && node.stage != 'Penerimaan' ? 'vertical-line' : 'receive'">
    <div 
      class="batch-container"
      :class="node.stage === 'Penerimaan' ? 'receive' : ''"
    >
      <div
        v-if="isNodeExpandable(node)"
        @click="nodeClick"
      >
        <span>
          {{ isNodeExpanded(node) ? '&#9660;' : '&#9658;' }}
        </span>
        <img 
          class="icon"
          :src="`${images}/icon/${isNodeExpanded(node) ? 'ic_batch_open.svg' : 'ic_batch_close.svg'}`"
          alt="icon-dropdown"
        >
        <span :class="isNodeExpanded(node) ? 'label-green' : 'uk-text-muted'">
          Batch {{ node.stage }} {{ node.code }}
        </span>
      </div>
      <div
        v-if="node.stage === 'Penerimaan'"
      >
        <span class="receive-rectangle">
          <a @click="handlePopUpImage(node.id)">{{ node.code }}</a>
        </span>
        <div
          :id="node.id"
          style="display: none;"
          class="popup-outer"
        >
          <div
            class="popup-trigger-area"
            @click="handleClosePopUpImage(node.id)"
          />
          <div
            class="popup-container"
          >
            <img :src="`${storageImage}/${node.picture}`">
          </div>
        </div>
      </div>
      <!-- Render children conditionally -->
      <TreeView
        v-for="child in node.details"
        v-if="showChildren"
        :key="child.id"
        :node="child"
      />
    </div>
  </div>
</template>
  
  
<script>
import { PREFIX_IMAGE, URL_IMAGE } from "@/utils/constant"
  
export default {
  name: 'TreeView',
  props: {
    node: { 
      type: Object, 
      required: true
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      storageImage: URL_IMAGE,
      firstNodeId: this.node.id,
      expandedNodes: [],
      curentNodeId: '',
      showChildren: false
    }
  },
  computed: {
    hasChildren() {
      return this.node.details && this.node.details.length > 0
    }
  },
  methods: {
    isNodeExpandable(node) {
      return node.details && node.details.length > 0
    },
    isNodeExpanded(node) {
      return this.expandedNodes.includes(node.id)
    },
    nodeClick() {
      if (this.isNodeExpandable(this.node)) {
        if (this.isNodeExpanded(this.node)) {
          this.expandedNodes = this.expandedNodes.filter(id => id !== this.node.id)
        } else {
          this.expandedNodes.push(this.node.id)
        }
      }
      this.curentNodeId = this.node.id
      this.showChildren = !this.showChildren
    },
    handlePopUpImage(s){
      document.getElementById(s).style.display = "block"
    },
    handleClosePopUpImage(s){
      document.getElementById(s).style.display = 'none'
    }
  }
}
</script>
  
<style>
.vertical-line {
  position: relative;
  left: 6px;
  top: -11px;
  border-left: solid 1px #828282;
  padding: 0 10px 0 10px;
}

.batch-container {
    margin: 20px 20px 0 20px;
}

.receive-rectangle {
  margin-right: 5px;
  margin-top: 20px;
  padding: 4px 8px;
  border-radius: 4px;
  background: #F4F4F4;
}

.batch-container .batch-container .receive{
    margin: 5px 0 0 3px;
}

.batch-container .receive{
    display: inline-block;
}

a, a:hover{
  color: #000;
}

.popup-outer{
  position: relative;
}

.popup-trigger-area{
  border-radius: 24px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Transparent background with some opacity */
  z-index: 0; /* Ensure it's above other content */
  cursor: pointer; /* Change cursor to pointer on hover for UX */
}

.popup-container {
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  height: 400px;
  overflow: visible;
  padding: 20px;
  text-align: center;
}

.popup-container img {
  max-width: 100%;
  max-height: 100%;
}

</style>
