<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table
        class="uk-table uk-table-divider uk-table-middle uk-table-hover"
        aria-describedby="pulping-facility-table"
      >
        <header-pulping :table-header="listHeader" />
        <template v-if="listPlantation">
          <tbody>
            <tr
              v-for="(content, index) in listPlantation"
              :key="index"
            >
              <td>{{ content.plantation_code || '-' }}</td>
              <td>{{ content.plantation_name || '-' }}</td>
              <td>{{ content.plantation_partner_name || '-' }}</td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <empty-table
            :colspan="3"
          />
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import HeaderPulping from './header.vue'
import EmptyTable from "@/components/globals/table/EmptyTable"

export default {
  components: {
    HeaderPulping,
    EmptyTable
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listPlantation: {
      required: true,
      type: Array
    },
    listHeader: {
      required: true,
      type: Array
    }
  }
}
</script>

<style scoped>
.disabled {
  cursor: no-drop;
}
</style>
