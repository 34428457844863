<template>
  <div
    :id="modalId"
    esc-close
    bg-close
    uk-modal
  >
    <div class="uk-modal-dialog uk-modal-body">
      <h2 class="uk-modal-title uk-text-left uk-margin-remove">
        {{ modalTitle }}
      </h2>
      <hr class="uk-margin-remove-top">
      <tree-view 
        :node="root"
      />
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from "@/utils/constant"
import TreeView from "./TreeView.vue"

export default {
  components: {
    TreeView
  },
  props: {
    modalId: {
      required: true,
      type: String
    },
    modalTitle: {
      required: false,
      default: "",
      type: String
    },
    dataItems: {
      required: true,
      type: Array
    }
  },
  data(){
    return{
      images: PREFIX_IMAGE,
      root:{}
    }
  },
  watch: {
    dataItems() {
      this.root = this.dataItems[0]
    }
  }
}
</script>

<style scoped>
.batch-list{
  list-style: none !important;
}

.progress-bar-step {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
}

.progress-bar-step::before {
  content: "";
  position: absolute;
  background: #4f5251;
  width: 1px;
  height: 100%;
  top: 30px;
  left: 9%;
  z-index: 1;
}

.tree-view-line {
  border-left: 1px solid #ccc;
  padding-left: 10px;
  cursor: pointer;
}

.uk-modal-title {
  font-size: 24px;
  font-weight: 600;
}

.uk-modal-body {
  width: 1000px;
  box-shadow: 0px 8px 40px rgba(12, 50, 96, 0.05);
  border-radius: 24px;
}
</style>
