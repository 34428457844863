<template>
  <thead class="thead-table-varion">
    <tr>
      <th
        v-for="(header, i) in tableHeader"
        :key="i"
        class="uk-table-expand uk-width-small"
      >
        <div
          v-if="header.custom === 'sort'"
          class="uk-flex cursor-pointer"
          align-item="center"
          @click.prevent="handleChangeSortFilter(header)"
        >
          <span>{{ header.label }}</span>
          <div class="uk-flex uk-flex-column uk-margin-small-left uk-flex-center">
            <span
              class="sort-icon"
              uk-icon="icon: chevron-up"
              :class="sorting.sort_by === header.key && sorting.sort === 'DESC' && 'sort-icon-grey'"
            />
            <span
              class="sort-icon sort-down"
              uk-icon="icon: chevron-down"
              :class="sorting.sort_by === header.key && sorting.sort === 'ASC' && 'sort-icon-grey'"
            />
          </div>
        </div>
        <div v-else>
          <span>{{ header.label }}</span>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  props: {
    tableHeader: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      images: PREFIX_IMAGE,
      sorting: {
        sort_by: '',
        sort: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      metaFilter: 'pulpingFacility/meta_filter'
    })
  },
  methods: {
    ...mapMutations({
      setMetaFilter: 'pulpingFacility/SET_META_FILTER'
    }),
    ...mapActions({
      actionGetDetailPulping: 'pulpingActivity/getDetailPulping'
    }),
    async handleChangeSortFilter(header){
      switch (true) {
      case this.sorting.sort_by !== header.key || (this.sorting.sort_by === header.key && this.sorting.sort === ''):
        this.sorting= {
          sort: 'ASC',
          sort_by: header.key
        }          
        break
      case this.sorting.sort_by === header.key && this.sorting.sort === 'ASC':
        this.sorting= {
          sort: 'DESC',
          sort_by: header.key
        }          
        break
      default:
        this.sorting= {
          sort: '',
          sort_by: header.key
        }
        break
      }
      this.setMetaFilter({
        ...this.metaFilter,
        ...this.sorting
      })
      await this.actionGetDetailPulping({...this.metaFilter, id:this.id, page: 1, limit: 10})
    }
  }
}
</script>
